<template>
  <div class="navbar-main">
    <div class="topnav">
      <img src="/images/alt-logo.jpg" />
      <div id="links">
        <router-link class="text-lg grey nav-item link" active-class="active-link" to="/" exact @click.native="myFunction()">Home</router-link>
        <router-link class="text-lg grey nav-item link" active-class="active-link" to="/services" exact @click.native="myFunction()">Services</router-link>
        <router-link class="text-lg grey nav-item link" active-class="active-link" to="/about" exact @click.native="myFunction()">About</router-link>
        <router-link class="text-lg grey nav-item link" active-class="active-link" to="/contact" exact @click.native="myFunction()">Contact</router-link>
      </div>
      <a class="icon" v-on:click="myFunction()">
        <i class="fa fa-bars"></i>
      </a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MobileNavbar',
  methods: {
    myFunction() {
      var x = document.getElementById("links");
      if (x.style.display === "block") {
        x.style.display = "none";
      } else {
        x.style.display = "block";
      }
    },
  },
};
</script>


<style scoped>
.navbar-main {
  margin-bottom: 5%;
}

.topnav {
  overflow: hidden;
  background-color: white;
  position: relative;
}

.topnav img {
  width: 75%;
  margin: 10px;
}

.topnav #links {
  display: none;
}

.topnav .nav-item {
  color: black;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  display: block;
}

.topnav a.icon {
  color: #4b4b4b;
  font-size: 32px;
  display: block;
  position: absolute;
  right: 25px;
  top: 20px;
}

.topnav .nav-item:hover {
  background-color: rgb(216, 216, 216);
  color: black;
}

.active-link {
  background-color: #c2c2c2;
  color: white;
}


/*********************/
/* Responsive Design */
/*********************/

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .topnav a.icon {
    color: #4b4b4b;
    font-size: 48px;
    display: block;
    position: absolute;
    right: 80px;
    top: 60px;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  
}

</style>