<template>
  <div class="main">
    <div class="main-about">
      <div class="about-content">
        <span class="text-lg black abrilFont">
          Coleman Sprinklers, Inc. 
        </span>
        <span v-if="windowWidth > 768" class="text-lg text-300 cormorantFont greyBlack">
          is a full service sprinkler
          and irrigation company serving residential and commercial 
          customers in the Bitterroot Valley areas. 
          We offer complete installation of new systems on new and 
          existing yards. We provide service and repair for all brands
          of sprinkler products.
        </span>
        <span v-else class="text-lg text-300 cormorantFont greyBlack">
          is a full service sprinkler
          and irrigation company serving residential and commercial
          customers in the Bitterroot Valley areas. 
        </span>
        <img v-if="windowWidth < 768" src="/images/sprayhead.jpg" />
        <span v-if="windowWidth < 768" class="text-lg text-300 cormorantFont greyBlack">
          We offer a wide range of products and services including 
          remodeling of existing systems and repairs to all brands of sprinkler systems.
        </span>
        <div class="about-service text-lg text-300 cormorantFont greyBlack">
          We service the following areas:
          <ul>
            <li>Lolo</li>
            <li>Florence</li>
            <li>Stevensville</li>
            <li>Victor</li>
            <li>Corvallis</li>
            <li>Hamilton</li>
            <li>Darby</li>
            <li>Sula</li>
            <li>Pinesdale</li>
          </ul> 
        </div>
        <div class="about-service text-lg text-600 cormorantFont black">
          Contact Us For A Free Estimate!
        </div>
      </div>
      <img v-if="windowWidth > 768" src="/images/sprayhead.jpg" />
    </div>
    <div class="main-service">
      <div class="services text-xl text-700 cormorantFont greyBlack">
        Services
        <ul class="text-lg text-300">
          <li>New Installs</li>
          <li>Trenchless Installs</li>
          <li>Do-It-Yourself</li>
          <li>Product Upgrade</li>
          <li>Startup</li>
          <li>Winterize / Blowouts</li>
          <li>Service All Brands</li>
        </ul>
      </div>
    </div>
    <div class="main-contact">
      <div class="contact-us text-xl text-700 cormorantFont greyBlack">
        How can we help you?
        <div class="text-lg text-300">
          Contact us today and schedule your free estimate!
        </div>
        <router-link class="button text-lg text-600 cormorantFont" to="/contact">Contact Us</router-link>
      </div>
      <img src="/images/sprayhead3.jpg" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data() {
    return {
      windowWidth: window.innerWidth,
    }
  },
};
</script>


<style scoped>

.main {
  margin: 0 5%;
}

.main-about {
  margin: 10% 0;
}

.about-content {
  margin: 20px;
  line-height: 1.5;
}

.about-content img {
  margin: 5% 0;
  width: 100%;
}

.about-service {
  margin-top: 40px;
}

.about-service ul {
  margin-top: 10px;
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

.main-service {
  background-image: url("/images/park.jpg");
  background-repeat: no-repeat;
  width: 100%;
  height: 600px;
  background-size: cover;
}

.services {
  margin: 40px;
  background-color: white;
  position: absolute;
  padding: 45px;
}

.main-contact {
  margin: 10% 0;
}

.main-contact img {
  width: 100%;
  margin: 5% 0 0;
}

.contact-us {
  height: 100%;
}

.contact-us div {
  margin: 25px 0;
}

.button {
  align-items: center;
  background-color: #A28067;
  border: 2px solid #A28067;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  fill: #000;
  height: 48px;
  justify-content: center;
  letter-spacing: -.8px;
  line-height: 24px;
  min-width: 140px;
  outline: 0;
  padding: 0 17px;
  text-align: center;
  text-decoration: none;
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button:focus {
  color: #171e29;
}

.button:hover {
  background-color: #B99F8D;
  border-color: #B99F8D;
  fill: #B99F8D;
}

.button:active {
  background-color: #B99F8D;
  border-color: #B99F8D;
  fill: #B99F8D;
}


/*********************/
/* Responsive Design */
/*********************/

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    @keyframes slides {
    0% {
      background-image: url("/images/slideshow2.jpg");
    }
    25% {
      background-image: url("/images/slideshow1.jpg");
    }
    50% {
      background-image: url("/images/park.jpg");
    }
    75% {
      background-image: url("/images/slideshow4.jpg");
    }
    100% {
      background-image: url("/images/slideshow6.jpg");
    }
  }

  .main-service {
    background-image: url("/images/slideshow2.jpg"), url("/images/slideshow1.jpg"),  url("/images/park.jpg"), url("/images/slideshow4.jpg"), url("/images/slideshow6.jpg");
    background-repeat: no-repeat;
    width: 100%;
    height: 600px;
    background-size: cover;
    animation-name: slides;
    animation-duration: 25s;
    animation-iteration-count: infinite;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .main-about {
    margin: 10% 0;
    display: flex;
    justify-content: space-between;
  }

  .main-contact {
    margin: 10% 0;
    display: flex;
    justify-content: space-between;
  }

  .main-contact img {
    width: 45%;
    margin: 0 0;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  
}
</style>
