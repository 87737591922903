<template>
  <div class="main">
    <div class="main-banner">
      <img src="images/rotorhead.jpg" />
      <figcaption class="abrilFont">About Us</figcaption>
    </div>
    <div class="main-about">
      <div class="column">
        <div class="about-header text-xl abrilFont greyBlack">
          Who We Are
        </div>
      </div>
      <div id="second-column" class="column text-lg text-500 greyBlack cormorantFont">
        <div class="about-text">
          Coleman Sprinklers of Stevensville Montana, a Rain Bird™ Select Contractor,
          is a family owned business. Since 1994 Coleman Sprinklers has been offering irrigation
          solutions, design, installation and service for homes and businesses
          throughout Missoula and the Bitterroot Valley.
        </div>
        <div class="about-text">
          When you choose Coleman Sprinklers, Inc., you can expect the highest level
          of care and attention will be brought to your specific irrigation needs,
          water-efficient design, and high quality installation using
          Rain Bird™ Professional Series products. Together, these will make the
          difference between a reliable system that keeps your grass green in the
          heat of the summer and one that doesn’t.
        </div>
      </div>
    </div>
    <div class="contractor-about">
      <div class="about-header text-xl abrilFont greyBlack">
        Rain Bird Select Contractor
      </div>
      <div class="about-text text-lg greyBlack cormorantFont">
        A Select Contractor specializes in the installation and maintenance of
        quality residential and commercial irrigation systems and is committed
        to providing you with the best value in sprinkler systems. Each Rain Bird™
        Select Contractor has been in business at least three years and adheres to
        a specific code of responsibilities.
      </div>
      <div class="contractor-text greyBlack cormorantFont">
        <div class="text-lg text-800">
          Select Contractor Requirements
        </div>
        <ul class="text-md">
          <li>Use Rain Bird water efficient products, the best quality products on the market today.</li>
          <li>Maintain a professional irrigation certification.</li>
          <li>Continuously meet state and local licensing and insurance requirements.</li>
          <li>Respond to job leads within 24-48 hours.</li>
          <li>Provide each customer with a thorough system proposal, including an
              explanation of the work to be done and the time required to complete it.</li>
          <li>Design premium quality systems and install professional-grade products
              in accordance with industry standards and applicable codes.</li>
          <li>Provide customers with a thorough “walk-through” of the completed system,
              including component locations and operation.</li>
          <li>Exceed customer expectations by leaving each job site in the best condition possible.</li>
          <li>Ensure that crew members and company vehicles present a consistent, professional image.</li>
        </ul>
        <div class="text-lg text-800">
          Invest Wisely
        </div>
        <div class="contractor-subtext text-md">
          By hiring a Rain Bird Select Contractor, you can be assured that your new
          irrigation system will be properly designed and installed by top
          professionals.  Rain Bird Select Contractors must follow rigorous
          professional standards and meet strict program requirements.  You can be
          certain that Select Contractors are fully qualified to design and install
          your automatic sprinkler system. In addition, you will have full confidence
          that the products used are appropriate for each zone of your landscape, are
          capable of delivering the precise amount of water needed, and are durable enough to last.
        </div>
        <div class="text-lg text-800">
          Enjoy Peace of Mind
        </div>
        <div class="contractor-subtext text-md">
          For more than seven decades, Rain Bird has been providing the industry’s most
          water-efficient, durable and reliable irrigation components, helping homeowners
          like you enjoy a hassle-free, beautiful lawn and landscape. Ask your friends and
          neighbors, more homeowners choose Rain Bird automatic sprinkler systems than any
          other brand. With the help of a Rain Bird Select Contractor, you can rest assured
          your system will be properly designed and professionally installed so you don’t
          have to worry about it, whether you’re at home or away.
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'About',

};
</script>

<style scoped>

.main {
  margin: 0 4%;
}

.main-banner {
  position: relative;
}

.main-banner img {
  width: 100%;
}

.main-banner figcaption {
  position: absolute;
  bottom: 20px;
  left: 10px;
  color: white;
  font-size: 32px;
}

.main-about {
  margin: 5% 3%;
}

#second-column {
  margin-left: 10%;
}

.about-header {
  padding: 0 1.5%;
  border-left: 4px solid #A28067;
  margin-bottom: 5%;
}

.about-text {
  margin: 5% 0 10%;
}

.contractor-about {
  margin: 8% 0 15% 3%;
}

.contractor-text {
  margin: 3% 0 0 5%;
}

.contractor-subtext {
  margin: 5% 0 5% 2%;
}


/*********************/
/* Responsive Design */
/*********************/

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .main-banner figcaption {
    position: absolute;
    bottom: 40px;
    left: 20px;
    color: white;
    font-size: 48px;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .main-banner figcaption {
    position: absolute;
    bottom: 60px;
    left: 30px;
    color: white;
    font-size: 52px;
  }

  .about-text {
    margin: 5% 15% 5%;
  }

  .contractor-about {
    margin: 8% 0 5% 3%;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  
}

</style>