<template>
  <div class="main">
    <div class="main-banner">
      <img src="images/rotorhead4.jpg" />
      <figcaption class="abrilFont">Services</figcaption>
    </div>
    <div class="main-services">
      <div>
        <div class="services-header text-xl abrilFont greyBlack">
          What We Do
        </div>
        <div class="text-lg text-500 greyBlack cormorantFont">
          <div class="services-text">
            At Coleman Sprinklers we specialize in irrigation systems,
            from startups to winterizations. This includes design and installation
            of a new system. We have the knowledge, personnel, and equipment to do the job.
          </div>
          <div class="services-text">
            The automatic sprinkler and irrigation systems from Coleman Sprinklers
            standout from the competition and assure you of an exceptional watering
            system for your home or business. You deserve the best system for your
            investment dollar which is why we install the highest quality product
            with the best warranty available and we will not cut corners to beat
            someone else's price.
          </div>
        </div>
      </div>
      <img src="images/rotorhead3.jpg" />
    </div>
    <div class="service-grid">
      <div class="services-header text-xl abrilFont greyBlack">
        Our Services
      </div>
      <div class="grid greyBlack">
        <div id="card">
          <div class="text-lg abrilFont">
            New Installations
          </div>
          <div class="service-content text-md cormorantFont">
            We provide irrigation designs and solutions for new or
            existing lawns, sports fields,
            flower beds, gardens, and trees, including pump and filter installation.
          </div>
        </div>
        <div id="card">
          <div class="text-lg abrilFont">
            Do-It-Yourself
          </div>
          <div class="service-content text-md cormorantFont">
            We install the filter, backflow preventer, controller, valves,
            wires, mainline, and lateral lines for your sprinkler system. 
            We supply you with a design for the system and all of the necessary
            parts to complete the installation as well as a demonstration on how
            to install heads and fittings.
          </div>
        </div>
        <div id="card">
          <div class="text-lg abrilFont">
            Additions
          </div>
          <div class="service-content text-md cormorantFont">
            You always have the option of adding to or modifying your existing
            sprinkler system. We can add another portion of your yard, or supply
            water to flower beds surrounding your deck or house or even to the garage,
            barn, etc.  We move heads, valves, controllers, add zones, modify zones
            from grass to hardscaping (drip), extend tree zones or add garden areas.
          </div>
        </div>
        <div id="card">
          <div class="text-lg abrilFont">
            Product Upgrades
          </div>
          <div class="service-content text-md cormorantFont">
            Go Green! Install a Smart Controller and save 30% or more on your water
            bill. The Smart Controller will regulate the run time for each zone
            depending on rainfall, temperature, soil type, and sun exposure,
            a Fertigation unit applies fertilizer with every application of water,
            a Rain Sensor will shut down your system if we receive a specific amount of moisture.
          </div>
        </div>
        <div id="card">
          <div class="text-lg abrilFont">
            Spring Startups
          </div>
          <div class="service-content text-md cormorantFont">
            It's a good idea to schedule a spring startup. Our technicians will
            turn on your water supply, program the controller, clean filters, check each valve
            and sprinkler head is running properly and you are ready for the summer watering season.
            Any additional work including moving/adding heads or drip will need a seperate
            service call after we complete our startups.
          </div>
        </div>
        <div id="card">
          <div class="text-lg abrilFont">
            Service All Brands
          </div>
          <div class="service-content text-md cormorantFont">
            Our experienced service staff are able to handle repairs on Rain Bird,
            Hunter, Nelson, Signature, K-Rain, Toro, Weathermatic, Hardie, Irritrol,
            Orbit, Wilkins, Watts, Gould’s, Rusco, Lakos sprinkler systems and products.
          </div>
        </div>
        <div id="card">
          <div class="text-lg abrilFont">
            Trenchless Installations
          </div>
          <div class="service-content text-md cormorantFont">
            We use a state of the art Ditch Witch vibratory plow to install our pipe
            and wire. This machine provides minimal disturbance to your property while
            installing your system.
          </div>
        </div>
        <div id="card">
          <div class="text-lg abrilFont">
            Winterizations
          </div>
          <div class="service-content text-md cormorantFont">
            Be sure to contact us in early fall to schedule a winterization for your
            sprinkler system to protect against potential damage from freezing.
          </div>
        </div>
        <div id="card">
          <div class="text-lg abrilFont">
            Free Estimates
          </div>
          <div class="service-content text-md cormorantFont">
            Free Estimates for new installations, smart controllers, and fertilization units.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Services',

};
</script>


<style scoped>

.main {
  margin: 0 4%;
}

.main-banner {
  position: relative;
}

.main-banner img {
  width: 100%;
}

.main-banner figcaption {
  position: absolute;
  bottom: 20px;
  left: 10px;
  color: white;
  font-size: 32px;
}

.main-services {
  margin: 6% 3%;
}

.main-services img {
  width: 100%;
  margin: 3% 0;
}

.services-header {
  padding: 0 1.5%;
  border-left: 4px solid #A28067;
}

.services-text {
  margin: 10% 0 10% 2%;
}

.service-grid {
  margin: 10% 3%;
}

.grid {
  display: grid;
  grid-gap: 1px;
  grid-template-columns: repeat(1, 1fr);
  margin: 4% 0 0 0;
}

.grid #card {
  padding: 20px;
  background-color: #f6f0ec;
}

.service-content {
  margin-top: 10px;
}

/*********************/
/* Responsive Design */
/*********************/

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .main-banner figcaption {
    position: absolute;
    bottom: 40px;
    left: 20px;
    color: white;
    font-size: 48px;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .main-banner figcaption {
    position: absolute;
    bottom: 60px;
    left: 30px;
    color: white;
    font-size: 52px;
  }

  .main-services {
    margin: 6% 3%;
    display: flex;
    justify-content: space-between;
  }

  .main-services img {
    width: 45%;
    margin: 3% 0 3% 3%;
  }

  .grid {
    display: grid;
    grid-gap: 1px;
    grid-template-columns: repeat(3, 1fr);
    margin: 4% 0 0 0;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  
}
</style>