<template>
  <div class="navbar-main">
    <div class="logo">
      <img src="/images/alt-logo.jpg" />
    </div>
    <div class="nav cormorantFont">
      <router-link class="text-lg grey nav-item link" active-class="active-link" to="/" exact>Home</router-link>
      <router-link class="text-lg grey nav-item link" active-class="active-link" to="/services" exact>Services</router-link>
      <router-link class="text-lg grey nav-item link" active-class="active-link" to="/about" exact>About</router-link>
      <router-link class="text-lg grey nav-item link" active-class="active-link" to="/contact" exact>Contact</router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Navbar',

};
</script>


<style scoped>
  .navbar-main {
    width: 100%;
    height: 100%;
  }

  .logo {
    margin-top: 10px;
    text-align: center;
  }

  .logo img {
    height: 120px;
  }

  .nav {
    text-align: center;
    margin: 25px 0px;
  }

  .nav-item {
    padding: 0 15px;
  }

  .nav-item:not(:last-child) {
    border-right: 1px solid #3E6548;
    padding: 0 45px;
  }

  .active-link {
    color: #3E6548;
    text-decoration: underline;
    text-underline-offset: 8px;
  }

</style>