<template>
  <div>

  </div>
</template>

<script>

  export default {
    name: 'NotFound',
    mounted() {
      this.$router.push('Contact');
    },
  }

</script>

<style scoped>

</style>