<template>
  <div class="main">
    <div class="main-contact">
      <div class="columns">
        <div class="contact-details">
          <div class="text-xl text-500 greyBlack abrilFont">
            Contact Us Later
          </div>
          <div class="details text-lg greyBlack cormorantFont">
            <div class="detail-item">
              <div id="content">Coleman Sprinklers Inc.</div>
              <div id="content">P.O. Box 367</div>
              <div id="content">Stevensville, MT 59870</div>
            </div>
            <div class="detail-item">
              (406)-777-0481
            </div>
            <div class="detail-item">
              lyle@colemansprinklers.com
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="contact-form">
          <div class="form_title text-xl text-500 greyBlack abrilFont">Contact Us Now</div>
          <form class="form details" accept-charset="utf-8" :action="actionUrl" method="post">
            <input required name="name" v-model='contact.name' placeholder="Name" type="text" autocomplete="off">
            <input required name="email" v-model="contact.email" placeholder="E-mail" type="email" autocomplete="off">
            <input required name="phone" v-model="contact.phone" placeholder="Phone Number" type="tel" autocomplete="off">
            <input required name="address" v-model='contact.address' placeholder="Address" type="text" autocomplete="off">
            <input type="hidden" name="_next" :value="redirect">
            <input type="text" name="_honey" style="display:none">
            <textarea name="message" v-model="contact.message" rows="4" placeholder="Message"></textarea>
            <button class="button">Send</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Contact',
  data() {
    return {
      actionUrl: "https://formsubmit.co/fa01c369ad0a7160e19c19152b0b5751",
      redirect: "https://colemansprinklers.com/contact",
      contact: {
        name: '',
        email: '',
        message: '',
        phone: '',
        address: '',
      },
    }
  },
};
</script>

<style scoped>

.main-contact {
  margin: 10% 5%;
}

.details {
  margin-left: 5%;
}

.contact-details {
  margin: 0 0 10%;
}

.detail-item {
  margin: 5% 0 0;
}

.form {
	display: flex;
	flex-direction: column;
	font-size: 16px;
}

.form_title {
	text-align: left;
  margin-bottom: 5%;
}

.form input[type="email"],
.form input[type="text"],
.form input[type="tel"],
.form textarea {
	border: solid 1px #e8e8e8;
	font-family: 'Roboto', sans-serif;
	padding: 10px 7px;
	margin-bottom: 15px;
	outline: none;
}

.form textarea {
	resize: none;
}

.form .button {
	background: #244229;
	border: solid 1px #244229;
	color: white;
	cursor: pointer;
	padding: 10px 50px;
	text-align: center;
	text-transform: uppercase;
}

.form .button:hover {
	background: #345830;
	border: solid 1px #345830;
}

.form input[type="email"],
.form input[type="text"],
.form input[type="tel"],
.form textarea,
.form .button {
	font-size: 15px;
	border-radius: 3px
}

/*********************/
/* Responsive Design */
/*********************/

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .main-contact {
    margin: 10% 10%;
  }

  .details {
    margin-left: 10%;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .main-contact {
    margin: 10% 10%;
    display: flex;
  }

  .columns {
    flex: 50%;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  
}


</style>
