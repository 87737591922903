<template>
  <div class="footer-main">
    <div class="content">
      <div class="column text-md greyBlack text-300">(406)-777-0481</div>
      <div class="column text-md greyBlack text-300">lyle@colemansprinklers.com</div>
      <div class="column text-md greyBlack text-300">P.O. Box 367, Stevensville MT, 59870</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Footer',
};
</script>


<style scoped>
  .footer-main {
    background-color: #B99F8D;
    width: 100%;
    height: 100%;
    padding: 5% 0;
  }

  .content {
    height: 100%;
  }

  .column {
    margin: 20px 0 10px 5%;
  }

</style>