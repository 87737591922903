<template>
  <div id="app">
    <Navbar v-if="windowWidth > 992" />
    <MobileNavbar v-else />
    <router-view/>
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/layout/Navbar.vue';
import MobileNavbar from '@/components/layout/MobileNavbar.vue';
import Footer from '@/components/layout/Footer.vue';

export default {
  name: 'App',
  components: {
    MobileNavbar,
    Navbar,
    Footer,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    }
  },
};
</script>

<style>
  body {
    margin: 0 !important;
    font-family: 'Roboto', sans-serif;
  }

  .abrilFont {
    font-family: 'Abril Fatface', cursive !important;
  }

  .cormorantFont {
    font-family: 'Cormorant Garamond', serif !important;
  }

  .link {
    text-decoration: none;
    color: inherit;
  }


  .text-sm {
    font-size: 18px;
    line-height: 1.1;
  }

  .text-md {
    font-size: 20px;
    line-height: 1.3;
  }

  .text-lg {
    font-size: 24px;
    line-height: 1.3;
  }

  .text-xl {
    font-size: 38px;
    line-height: 1.5;
  }

  .text-300 {
    font-weight: 300;
  }

  .text-500 {
    font-weight: 500;
  }

  .text-600 {
    font-weight: 600;
  }

  .text-700 {
    font-weight: 700;
  }

  .text-800 {
    font-weight: 800;
  }

  .greyBlack {
    color: #4b4b4b;
  }

  .black {
    color: #333333;
  }

  .darkGreen {
    color: #244229;
  }

  .lightGreen {
    color: #345830;
  }

  .darkTan {
    color: #A28067;
  }

  .lightTan {
    color: #B99F8D;
  }

</style>
